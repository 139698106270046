
import Request from '@/utils/request'

export function getSpecsListApi (data) {
  return Request({
    url: '/specs',
    params: data
  })
}

export function saveSpecsApi (data) {
  return Request({
    url: '/saveSpecs',
    method: 'post',
    data
  })
}

export function getTypesApi () {
  return Request({
    url: '/type/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/specsbytype',
    params: data
  })
}

export function getTypeListApi (data) {
  return Request({
    url: '/type',
    params: data
  })
}
