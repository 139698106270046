<template>
  <div class="specs">
    <div class="top">
      <el-button @click="handleAdd" type="primary">新增规格</el-button>
      <el-tabs v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane v-for="(item,index) in typeList" :key="index" :label="`${item.type}(${item.count})`" :name="item._id"></el-tab-pane>
      </el-tabs>
    </div>
    <el-table v-loading="loading" :data="tableData" class="table" ref="table" :height="tableH" stripe style="width: 100%">
      <el-table-column prop="specs" label="规格" />
      <el-table-column prop="createTime" align="right" label="创建时间" />
    </el-table>
  </div>
  <EditDialog v-if="editDialogShow" :refresh-list="init" @close="handleDialogClose"/>

</template>

<script>
import { ElMessage } from 'element-plus'
import { onMounted, ref, reactive, nextTick, toRefs, inject } from 'vue'
import EditDialog from './edit.vue'
import {
  getSpecByTypeApi,
  getTypesApi
} from './fetch'

export default {
  components: {
    EditDialog
  },
  setup () {
    const $msg = inject('$msg')
    const table = ref(null)
    const state = reactive({
      editDialogShow: false,
      typeList: [],
      tableH: 0,
      activeName: '',
      tableData: [],
      loading: false,
      selectedPane: ''
    })

    onMounted(async () => {
      init()
    })

    const init = async (refresh = false) => {
      const res = await getTypesApi()
      state.typeList = res
      if (res.length && !refresh) {
        state.selectedPane = state.typeList[0]._id
        state.activeName = state.typeList[0]._id
      }
      await getList()
      getHeight()
    }
    const getList = async () => {
      try {
        state.loading = true
        const res = await getSpecByTypeApi({
          typeId: state.selectedPane
        })
        state.tableData = res
      } catch (e) {
        $msg({
          message: e,
          type: 'error'
        })
      } finally {
        state.loading = false
      }
    }
    const getHeight = () => {
      state.tableH = table.value.$el.offsetHeight
      console.log(state.tableH)
    }

    window.onresize = () => {
      nextTick(() => {
        getHeight()
      })
    }

    const handleAdd = () => {
      state.editDialogShow = true
    }
    const handleDialogClose = () => {
      state.editDialogShow = false
    }

    const handleCurrentChange = async () => {
      try {
        await getList()
      } catch (e) {
        ElMessage({
          type: 'error',
          message: e
        })
      }
    }
    const handleTabClick = async (id) => {
      state.selectedPane = id.paneName
      getList()
    }

    return {
      table,
      ...toRefs(state),
      getList,
      init,
      handleAdd,
      handleDialogClose,
      handleCurrentChange,
      handleTabClick
    }
  }
}
</script>

<style lang="stylus" scoped>
.specs
  background-color #fff
  padding 20px
  height 100%
  display flex
  flex-direction: column
  .table
    height 0
    flex: 1
</style>
