<template>
  <el-dialog v-model="visible" :title="title" width="640px">
    <el-form ref="form" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别" prop="typeId">
        <el-select v-model="ruleForm.typeId" filterable placeholder="请选择">
          <el-option v-for="(item,index) in types" :key="index" :label="item.type" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="specs">
        <el-input v-model="ruleForm.specs"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { ref, computed, reactive, toRefs, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'

import {
  saveSpecsApi
} from './fetch'
import {
  getAllTypeApi
} from '../stock/fetch'

export default {
  props: {
    refreshList: Function
  },
  setup (props, ctx) {
    const form = ref(null)
    const route = useRoute()
    const { id } = route.params
    const state = reactive({
      visible: true,
      types: [],
      ruleForm: {
        typeId: '',
        specs: ''
      },
      rules: {
        typeId: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        specs: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      state.types = await getAllTypeApi()
    })

    watch(() => state.visible, (newVal) => {
      if (!newVal) {
        setTimeout(() => {
          ctx.emit('close')
        }, 300)
      }
    })

    const title = computed(() => {
      return id ? '编辑规格' : '新增规格'
    })

    const onSubmit = async () => {
      try {
        await form.value.validate()
        await saveSpecsApi(state.ruleForm)
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
        state.visible = false
        props.refreshList(true)
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    return {
      ...toRefs(state),
      title,
      form,
      onSubmit
    }
  }
}
</script>
